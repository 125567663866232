@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 1px;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: #f9f9f9;
}

a {
  color: inherit;
  text-decoration: none;
}

::placeholder {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 2px;
}

ul {
  list-style-type: none;
}
